import React, { Component } from 'react';
import charming from 'charming';
import anime from 'animejs';
import './App.css';
import {ProfileImage} from "./ProfileImage";

class App extends Component {

  componentDidMount() {
    const name = document.querySelector('.name');
    charming(name);

    const timeline = anime.timeline({
      loop: false,
      autoplay: false
    });

    timeline
      .add({
        targets: '.svgFace .lines path',
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInOutSine',
        duration: 1000,
        delay: (el, i) => i < 3 ? i * 200 : i * 10,
        direction: 'alternate',
      })
      .add({
        targets: '.c-hero__block',
        translateY: [80, 0],
        opacity: [0, 1],
        width: 250,
        height: 50,
        duration: 1500,
        elasticity: 50,
        offset: '-=300',
      })
      .add({
        targets: '.c-hero__block',
        width: 450,
        duration: 600,
        easing: 'easeInQuart',
      })
      .add({
        targets: '.c-hero__block .name > span:not(.char1):not(.char6)',
        translateY: [40, 0],
        opacity: 1,
        maxWidth: {
          value: 50,
          duration: 1250,
        },
        delay: (el, i) => i * 50,
        duration: 600,
        elasticity: 500,
        offset: '-=300',
      })
      .add({
        targets: '.c-hero__block',
        height: 220,
        duration: 500,
        delay: 100,
        easing: 'easeInQuart',
        offset: '-=600',
      })
      .add({
        targets: '.c-hero__block .name',
        paddingTop: ['10px', '20px'],
        paddingLeft: ['7px', '9%'],
        duration: 400,
        offset: '-=400',
        easing: 'easeInQuart',
      })
      .add({
        targets: '.c-hero__block__contact > *',
        opacity: 1,
        translateX: [40, 0],
        delay: (el, i) => i * 100,
        offset: '-=200',
      })
      .add({
        targets: '.c-hero__block .name > span:not(.char6):not(.char7):not(.char8):not(.char9)',
        opacity: 0.3,
        color: '#222255',
        fontSize: 20,
        delay: (el, i) => i * 50,
        duration: 600,
        elasticity: 200,
        offset: '-=500',
      });

    setTimeout(() => timeline.play(), 300);
  }

  render() {
    return (
      <div className="App">
        <div className="svgFace">
          <ProfileImage />
        </div>
        <div className="c-hero__block">
          <span className="name">Dev.&nbsp;Bro</span>

          <div className="c-hero__block__contact">
            <span>Software Engineer</span>

            <footer>
              <div className="email">
                <a href="mailto:jhj12010@gmail.com">
                  <span>jhj12010@gmail.com</span>
                </a>
              </div>
              <a
                href="https://www.facebook.com/people/%EC%A1%B0%ED%98%95%EC%9E%AC/100024643807347"
                target="_blank"
              >
                <span className="fb-logo" />
              </a>
              <a
                href="https://www.linkedin.com/in/hyeongjae-jo-bb7ab4137/"
                target="_blank"
              >
                <span className="linkedin-logo" />
              </a>
              <a
                href="https://brunch.co.kr/@devbro"
                target="_blank"
              >
                <span className="brunch-logo" />
              </a>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
